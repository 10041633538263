import { useNavigate } from "react-router-dom";
import { SIGNUP } from "../../../domain/routing";
import { LOGIN, REDIRECT_OAUTH_URL } from "../../../domain/apis";
import { useForm } from "react-hook-form";
import classNames from "classnames/bind";
import styles from "./Login.module.scss";
import authStyles from "../AuthStyles.module.scss";
import { BlindEye, Email, Eye, Github, Gitlab, Lock } from "@/components/Icons";
import OtherLogginBtn from "@/components/OtherLogginBtn";
import { loginForm, loginSchema } from "../Schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosClient, errorTypes } from "@/configs/AxiosInterceptor";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@/features/hooks";
import { setToast } from "@/features/toastSlice";
import { useState } from "react";
import { setSessionLogin } from "@/features/sessionSlice";
import { setProfile } from "@/features/profileSlice";

const cx = classNames.bind(styles);
const authCx = classNames.bind(authStyles);

export default function Login(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hidePsw, setHidePsw] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<loginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const onChangeStatePsw = () => {
    setHidePsw(!hidePsw);
  };

  const onSubmit = async (data: any) => {
    try {
      data.provider = "jwt";
      const result = await axiosClient.post(LOGIN, data);
      const userInfo = result.data.data;

      if (!result.data.data && result.data.status === 404) {
        dispatch(
          setToast({
            title: "Error",
            desc: result.data.title,
            isOpen: false,
            status_code: result.data.status,
          })
        );
        reset({ password: "" });
      } else {
        localStorage.setItem("login", "true");
        dispatch(
          setToast({
            title: "Success",
            desc: result.data.title,
            isOpen: false,
            status_code: result.data.status,
          })
        );
        dispatch(
          setProfile({
            email: userInfo.email,
            name: userInfo.name,
          })
        );
        dispatch(
          setSessionLogin({
            access_token: userInfo.token,
          })
        );
      }
    } catch (error: any) {
      const err: errorTypes = error;
      dispatch(
        setToast({
          title: "Error",
          desc: err.response.data,
          isOpen: false,
          status_code: err.response.status,
        })
      );
      reset({ password: "" });
    }
  };

  return (
    <div className={cx("wrapper")}>
      <div className={cx("logo-wrapper")}>
        <img src={"https://app.gcalls.co/assets/img/logo.png"} />
      </div>

      {/* form */}
      <div className={cx("form-wrapper")}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{Email}</div>
              <input
                {...register("email")}
                placeholder="Email"
                className={cx("input-item")}
              />
              <div className={cx("right-icon-wrapper")}></div>
            </div>
            <p className={authCx("error-msg")}>{errors.email?.message}</p>
          </div>
          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{Lock}</div>
              <input
                {...register("password")}
                placeholder="Password"
                className={cx("input-item")}
                type={!hidePsw ? "password" : "text"}
              />
              <div className={cx("right-icon-wrapper")}>
                <div onClick={onChangeStatePsw}>{hidePsw ? Eye : BlindEye}</div>
              </div>
            </div>
            <p className={authCx("error-msg")}>{errors.password?.message}</p>
          </div>
          <div className={cx("register-navigation-link-wrapper")}>
            <a
              href="#"
              className={cx("register-navigation-link")}
              onClick={() => {
                navigate(SIGNUP);
              }}
            >
              Don't have account?
            </a>
          </div>
          <input type="submit" value={"Login"} />
          <div className={cx("block-text")}>
            <span className={cx("text")}>Others</span>
          </div>
        </form>
        <div className={cx("other-login-btn-wrapper")}>
          <div className={cx("row-login-btn")}>
            <OtherLogginBtn
              path={Gitlab}
              title="Gitlab"
              backgroundColor="#FA7035"
              titleColor="white"
              redirectUrl={REDIRECT_OAUTH_URL}
              type="gitlab"
            />

            <OtherLogginBtn
              path={Github}
              title="Github"
              backgroundColor="#ffffff"
              titleColor="black"
              redirectUrl={REDIRECT_OAUTH_URL}
              type="github"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
