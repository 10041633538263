import * as yup from "yup";

export type RegisterForm = {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
};

export type loginForm = {
  email: string;
  password: string;
};

export const registerSchema = yup.object().shape({
  email: yup.string().email().required(),
  username: yup.string().min(6).max(32).required(),
  password: yup.string().min(6).max(32).required(),
  confirmPassword: yup
    .string()
    .min(6)
    .max(32)
    .oneOf([yup.ref("password"), undefined], "Password don't match")
    .required(),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).max(32).required(),
});
