import { Route, Routes } from "react-router-dom";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { axiosClient } from "../../configs/AxiosInterceptor";
import { BASE_URL, HISTORY_LOG_PREFIX } from "../../domain/apis";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./History.module.scss";
import classNames from "classnames/bind";
import Loading from "@/components/Loading";

const cx = classNames.bind(styles);

interface IhistoryDetailObject {
  [key: string]: string;
}
interface IhistoryDetailType extends IhistoryDetailObject {
  ip: string;
  browser: string;
  os: string;
  date: string;
  time: string;
}

function History() {
  const [history, setHistory] = useState<IhistoryDetailType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    const data = (
      await axiosClient.get(
        BASE_URL + HISTORY_LOG_PREFIX + "?page=" + page + "&limit=5"
      )
    ).data;
    const result = getHistoryDetails(data?.logs);
    setTotalPage(data?.totalPages);
    setPage(data?.currentPage);
    setHistory(result);
    setLoading(false);
  };

  function getHistoryDetails(data: any): IhistoryDetailType[] {
    if (data.length > 0) {
      const historyDetails = data.map((item: any) => {
        const detailObject = item.detail
          .split("&")
          .reduce((prev: any, curr: any) => {
            const [key, value] = curr.split("=");
            prev[key] = value;
            return prev;
          }, {} as IhistoryDetailObject);
        const timeArr = detailObject.currentDateTime.split(", ");
        const historyDetail = {
          ...detailObject,
          ip: detailObject.ip,
          browser: detailObject.browser,
          os: detailObject.os,
          date: timeArr[0],
          time: timeArr[1],
        };

        return historyDetail;
      });
      return historyDetails;
    }

    return [];
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [page]);

  console.log(page);
  

  const onHandleClick = (index: number) => {
    const number = index + 1;
    setPage(number);
  };

  return (
    <div className={cx("table-wrapper")}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "700" }}>
                    ip
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "700" }}>
                    browser
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "700" }}>
                    os
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "700" }}>
                    date
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "700" }}>
                    time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((row) => {
                  return (
                    <TableRow
                      key={row.currentDateTime}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.ip}</TableCell>
                      <TableCell align="center">{row.browser}</TableCell>
                      <TableCell align="center">{row.os}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.time}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={cx("pagination-wrapper")}>
            {[...Array(totalPages)].map((item, index) => {
              return (
                <div
                  key={index}
                  className={cx("item-pagination")}
                  style={
                    index === page - 1
                      ? { backgroundColor: "#673ab7", color: "white" }
                      : {}
                  }
                >
                  <button
                    onClick={() => onHandleClick(index)}
                    style={index === page - 1 ? { color: "white" } : {}}
                    className={cx("btn")}
                  >
                    {index + 1}
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default History;
