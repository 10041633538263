import Activitytemp from "@/pages/ActivityTemp";
import History from "@/pages/History/History";
import Login from "@/pages/Auth/Login";
import Profile from "@/pages/Profile";
import Register from "@/pages/Auth/Register";
import { RouteProps } from "react-router-dom";

type LayoutProps = {
  children: React.ReactNode;
};

type RoutePropsWithLayout = {
  layout?: React.ComponentType<LayoutProps> | null;
} & RouteProps;

const publicRoutes: RoutePropsWithLayout[] = [
  { path: "/", Component: Activitytemp, layout: null },
  { path: "/history", Component: History, layout: null },
  { path: "/profile", Component: Profile, layout: null },
];

const privateRoutes: RouteProps[] = [
  { path: "/login", Component: Login },
  { path: "/signup", Component: Register },
];

export { publicRoutes, privateRoutes };
