export const USER_PREFIX = "/users";
export const HISTORY_LOG_PREFIX = "/history-logs";
export const GET_PROFILE_USER = "/current-user";
export const FIND_USER_BY_EMAIL = "/find-user-by-email";
export const AUTH_PREFIX = "/auth/gitlab";
export const BASE_URL = "http://www.zenno.cloud:3001/api";
export const LOGOUT = "/logout";
export const REDIRECT_OAUTH_URL =
  "/oauth/login?redirect_uri=https://demo-online-test-gcalls-8ia.pages.dev";
export const LOGIN = "/oauth/login";
export const SIGNUP = "/oauth/signup";
