import { createSlice } from "@reduxjs/toolkit";
import { DIALER_CLOSE, DIALER_OPEN } from "../utils/constants";

type ToggleState = {
  toggle: boolean;
};

const initialState = (): ToggleState => {
  return { toggle: false };
};

export const ToggleSlice = createSlice({
  name: "toggle",
  initialState: initialState(),
  reducers: {
    resetToggle: () => {
      return initialState();
    },
    setState: (state, action) => {
      state.toggle = !action.payload;
    },
  },
});

export const { resetToggle, setState } = ToggleSlice.actions;

export default ToggleSlice.reducer;
