import axios, { AxiosError } from "axios";
import { BASE_URL } from "../domain/apis";
import { useDispatch } from "react-redux";
import { setSessionLogout } from "../features/sessionSlice";
import { store } from "../features/store";

export const AUTHORIZATION_KEY = "access_token";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000 * 10, // 30s
  headers,
});

axiosClient.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (config.headers == undefined) {
      config.headers = {};
    }

    // console.log('token:', token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (
      config.method === "post" &&
      config.headers["Content-Type"] == undefined
    ) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    // console.log("error request interceptor", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.info('Response:', JSON.stringify(response.data));
    return response;
  },
  async function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const axiosErr = error as AxiosError;
    if (axiosErr.response?.status === 401 || !axiosErr.response?.status) {
      store.dispatch(setSessionLogout({}));
    }

    // console.error(`AxiosError: ${JSON.stringify(axiosErr)}`);

    return Promise.reject(axiosErr);
  }
);

axiosClient.interceptors.request.use((request) => {
  // console.log('Starting Request', JSON.stringify(request, null, 2));
  return request;
});

export type errorTypes = {
  response: {
    data: {
      message: string;
    };
    status: Number;
  };
};
