import { combineReducers, configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./sessionSlice";
import ToggleReducer from "./toggleSlice";
import ContactReducer from "./contactSlice";
import ProfileReducer from "./profileSlice";
import ToastReducer from "./toastSlice";
import InternetSlice from "./internetSlice";

const combinedReducer = combineReducers({
  session: sessionReducer,
  toggle: ToggleReducer,
  phone: ContactReducer,
  profile: ProfileReducer,
  InternetSlice: InternetSlice,
  toast: ToastReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "session/setSessionLogout") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
