import classNames from "classnames/bind";
import styles from "./ActivityTemp.module.scss";
import { useEffect, useRef, useState } from "react";
import call from "@/utils/jsSIP";
import { RTCSession } from "jssip/lib/RTCSession";
import { STATUS } from "@/features/contactSlice";
import { useAppSelector } from "@/features/hooks";
const cx = classNames.bind(styles);
const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

type propTypes = {
  onChange: (event: any) => void;
  phone: string;
  focusInputRef: any;
  onBtnNumberClick: (item: string) => void;
  handleCall: () => void;
};

const DialogPad = (props: propTypes) => {
  const { onChange, focusInputRef, handleCall, onBtnNumberClick, phone } =
    props;
  return (
    <div className={cx("activity_content")}>
      <div className={cx("input-wrapper")}>
        <input onChange={onChange} value={phone} ref={focusInputRef} />
      </div>
      <div>
        {buttons.map((item, index) => {
          return (
            <button key={index} onClick={() => onBtnNumberClick(item)}>
              {item}
            </button>
          );
        })}
      </div>
      <div className={cx("btn-call-wrapper")}>
        <button onClick={handleCall}>
          <img src={require("@/assets/icons/call.png")} alt="icon_call" />
        </button>
      </div>
    </div>
  );
};

const Calling = (props: any) => {
  const { phone, canceledCall, status } = props;
  const [dots, setDots] = useState<string>("On calling");

  useEffect(() => {
    let count = 0;
    let interval: any;
    console.log(status);

    switch (status) {
      case STATUS.CONNECTING || STATUS.PROGRESS:
        interval = setInterval(() => {
          if (count < 3) {
            count++;
            setDots((prev) => prev + ".");
          } else {
            setDots("On calling");
            count = 0;
          }
        }, 1000);
        break;
    }
  }, [status]);

  return (
    <div className={cx("activity_content")}>
      <div className={cx("input_calling-wrapper")}>
        <input value={phone} readOnly />
      </div>
      <div className={cx("status-wrapper")}>
        <span>{dots}</span>
      </div>
      <div className={cx("btn-call-wrapper")}>
        <button onClick={canceledCall} className={cx("cancel_btn")}>
          <img src={require("@/assets/icons/call.png")} alt="icon_call" />
        </button>
      </div>
    </div>
  );
};

const Activitytemp = () => {
  const [phone, setPhone] = useState<string>("");
  const { makeCall } = call({ number: phone });
  const [sessionCall, setSessionCall] = useState<RTCSession>();
  const { status } = useAppSelector((state) => state.phone);
  const [audio, setAudio] = useState<any>(null);
  const focusInputRef = useRef<HTMLInputElement>(null);
  const onClick = (e: any) => {};

  const onChange = (event: any) => {
    setPhone(event.target.value);
  };

  const onBtnNumberClick = (number: string) => {
    setPhone((prev) => prev + number);
  };

  useEffect(() => {
    let event: any;
    if (sessionCall) {
      event = sessionCall.connection.addEventListener(
        "addstream",
        function (e: any) {
          // set remote audio stream
          const remoteAudio = document.createElement("audio");
          remoteAudio.srcObject = e.stream;
          remoteAudio.play();
        }
      );
    }
    return () => {
      if (event) {
        event.remove();
      }
    };
  }, [sessionCall]);

  const onBtnHidePopup = () => {};

  const handleCall = () => {
    const session = makeCall();
    setSessionCall(session);
  };

  const canceledCall = () => {
    try {
      sessionCall?.terminate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {status === STATUS.FREE ? (
        <DialogPad
          focusInputRef={focusInputRef}
          handleCall={handleCall}
          onBtnNumberClick={onBtnNumberClick}
          onChange={onChange}
          phone={phone}
        />
      ) : (
        <Calling phone={phone} canceledCall={canceledCall} status={status} />
      )}
    </div>
  );
};

export default Activitytemp;
