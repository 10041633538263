import { createSlice } from "@reduxjs/toolkit";

export const enum STATUS {
  ONLINE = "online",
  OFFLINE = "offline",
}

type SessionState = {
  browser: string;
  status_connection: STATUS;
  operating_system: string;
};

const initialState = (): SessionState => {
  return {
    status_connection: STATUS.ONLINE,
    browser: "",
    operating_system: "",
  };
};

export const InternetSlice = createSlice({
  name: "contact",
  initialState: initialState(),
  reducers: {
    resetInternet: () => {
      return initialState();
    },
    setInternet: (state, action) => {
      state.browser = action.payload.browser;
      state.operating_system = action.payload.operating_system;
      state.status_connection = action.payload.status_connection;
    },
  },
});

export const { resetInternet, setInternet } = InternetSlice.actions;

export default InternetSlice.reducer;
