import { createSlice } from "@reduxjs/toolkit";

export const enum STATUS {
  FAILED = "FAILED",
  CONNECTING = "CONNECTING",
  ENDED = "ENDED",
  PROGRESS = "PROGRESS",
  STARTED = "STARTED",
  FREE = "FREE",
}

type SessionState = {
  phone: string;
  isPhoneRang: boolean;
  status: STATUS;
  sessions: any;
  reason: string;
};

const initialState = (): SessionState => {
  return {
    phone: "",
    isPhoneRang: false,
    status: STATUS.FREE,
    sessions: null,
    reason: "",
  };
};

export const phoneSlice = createSlice({
  name: "contact",
  initialState: initialState(),
  reducers: {
    resetPhone: () => {
      return initialState();
    },
    setPhone: (state, action) => {
      state.phone = action.payload.phone;
      state.isPhoneRang = action.payload.isPhoneRang;
      state.status = action.payload.status;
      state.reason = action.payload.reason;
    },
    setSessions: (state, action) => {
      state.sessions = action.payload.sessions;
    },
  },
});

export const { resetPhone, setPhone, setSessions } = phoneSlice.actions;

export default phoneSlice.reducer;
