import * as JsSIP from "jssip";
import { store } from "../features/store";
import { STATUS, setPhone } from "../features/contactSlice";

type props = {
  number: string;
};

const call = ({ number }: props) => {
  const socket = new JsSIP.WebSocketInterface("wss://gc03-pbx.tel4vn.com:7444"); // thay đổi địa chỉ SIP server tương ứng
  const configuration = {
    sockets: [socket],
    uri: "103@2-test1.gcalls.vn:50061",
    password: "test1103",
  };

  var ua = new JsSIP.UA(configuration);

  ua.start();

  var session: any = null;

  // Register callbacks to desired call events
  var eventHandlers = {
    connecting: function (e: any) {
      console.log(`123`);

      store.dispatch(
        setPhone({
          phone: number,
          isPhoneRang: true,
          status: STATUS.CONNECTING,
        })
      );
    },

    progress: function (e: any) {
      if (e.originator === "remote") {
        store.dispatch(
          setPhone({
            phone: number,
            isPhoneRang: true,
            status: STATUS.PROGRESS,
          })
        );
      }
    },
    failed: function (e: any) {
      store.dispatch(
        setPhone({
          phone: number,
          isPhoneRang: false,
          status: STATUS.FREE,
          reason: e.cause,
        })
      );
      alert(e.cause);
    },
    ended: function (e: any) {
      store.dispatch(
        setPhone({ phone: number, isPhoneRang: false, status: STATUS.FREE })
      );
    },
    started: function (e: any) {
      console.log("start");

      store.dispatch(
        setPhone({
          phone: number,
          isPhoneRang: true,
          status: STATUS.STARTED,
        })
      );
    },
  };

  var options = {
    eventHandlers: eventHandlers,
    mediaConstraints: { audio: true, video: true },
  };

  const makeCall = () => {
    session = ua.call(`sip:${number}@2-test1.gcalls.vn:50061`, options);
    return session;
  };

  return { makeCall };
};

export default call;
