import classNames from "classnames/bind";
import styles from "./OtherLogginBtn.module.scss";
import { BASE_URL } from "@/domain/apis";

const cx = classNames.bind(styles);

type propTypes = {
  path: JSX.Element;
  title: string;
  titleColor: string;
  backgroundColor: string;
  redirectUrl: string;
  type: string;
};

const OtherLogginBtn = (props: propTypes) => {
  const { path, title, titleColor, backgroundColor, redirectUrl, type } = props;
  const openLoginWindow = () => {
    console.log(
      BASE_URL + redirectUrl + "&type=" + type,
      "_blank",
      "width=800,height=600"
    );

    window.open(
      BASE_URL + redirectUrl + "&type=" + type,
      "_blank",
      "width=800,height=600"
    );
  };

  return (
    <button className={cx("wrapper")} onClick={openLoginWindow}>
      <div
        className={cx("button-login-wrapper")}
        style={
          backgroundColor
            ? {
                backgroundColor: backgroundColor,
              }
            : {}
        }
      >
        <div className={cx("icon-wrapper")}>{path}</div>
        <div className={cx("separate")}></div>
        <div className={cx("title-wrapper")}>
          <span
            style={
              titleColor
                ? {
                    color: titleColor,
                  }
                : {}
            }
          >
            {title}
          </span>
        </div>
      </div>
    </button>
  );
};

export default OtherLogginBtn;
