import { useAppDispatch, useAppSelector } from "@/features/hooks";
import styles from "./Profile.module.scss";
import classNames from "classnames/bind";
import { setSessionLogout } from "@/features/sessionSlice";

const cx = classNames.bind(styles);

const Profile = () => {
  const dispatch = useAppDispatch();
  const { email, img, name } = useAppSelector((state) => state.profile);

  function handleLogout() {
    dispatch(
      setSessionLogout({
        logout: "true"
      })
    );
  }

  return (
    <div className={cx("profile-wrapper")}>
      <div className={cx("img-wrapper")}>
        {img.length > 9 && <img src={img} alt="profile_avatar" />}
      </div>
      <div className={cx("basic_profile--wrapper")}>
        <div className={cx("name")}>{name}</div>
        <div className={cx("email")}>{email}</div>
      </div>
      <div className={cx("btn-wrapper")}>
        <button className={cx("btn_logout")} onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Profile;
